import {Observable, ReplaySubject, Subject, throwError} from 'rxjs';
import {catchError, delay, finalize, share, takeUntil, tap} from 'rxjs/operators';
import {ToastService} from '../toast.service';
import {extractErrorMessage} from '../../utils/error.utils';
import {capitalize} from '../../utils/string.utils';
import {HttpErrorResponse} from '@angular/common/http';

export interface RequestHandlerOptions {
    cancel$?: Observable<any>;
    destroy$?: Observable<any>;
    description?: string;
    successMessage?: string;
    defaultErrorMessage?: string;
    showSuccessToast?: boolean;
    showErrorToast?: boolean;
    errorLookUpKeys?: string[];
}

export type RequestStatus = 'PENDING' | 'SUCCESS' | 'ERROR';

export class RequestHandler<F extends (...args) => Observable<any>, T extends F extends (...args) => Observable<infer T> ? T : unknown> {
    private _obsFn: F;
    private _result: T;
    private _cancel$ = new Subject<void>();
    private _destroy$ = new Subject();
    private _description = 'process request';
    private _successMessage: string;
    private _defaultErrorMessage: string;
    private _status: RequestStatus;
    private _status$ = new ReplaySubject<RequestStatus>(1);
    private _error: HttpErrorResponse;
    private _errorMessage: string;
    private _showSuccessToast = false;
    private _showErrorToast = true;
    private _errorLookUpKeys: string[];

    get result() {
        return this._result;
    }

    get status() {
        return this._status;
    }

    get status$() {
        return this._status$.asObservable();
    }

    get isPending() {
        return this.status === 'PENDING';
    }

    get isReady() {
        return this.status === 'SUCCESS';
    }

    get error() {
        return this._error;
    }

    get errorMessage() {
        return this._errorMessage;
    }

    constructor(obsFn: F, options?: RequestHandlerOptions) {
        this._obsFn = obsFn;

        if (options?.cancel$) options.cancel$.subscribe(this._cancel$);
        if (options?.destroy$) options.destroy$.subscribe(this._destroy$);
        if (options?.description) this._description = options.description;
        if (options?.successMessage) this._successMessage = options.successMessage;
        if (options?.defaultErrorMessage) this._defaultErrorMessage = options.defaultErrorMessage;
        if (options?.errorLookUpKeys) this._errorLookUpKeys = options.errorLookUpKeys;
        if (typeof options?.showSuccessToast === 'boolean') this._showSuccessToast = options.showSuccessToast;
        if (typeof options?.showErrorToast === 'boolean') this._showErrorToast = options.showErrorToast;

        this._destroy$.subscribe(() => this.destroy());
    }

    call(...args: Parameters<F>): Observable<T> {
        this._cancel$.next();
        this.updateStatus('PENDING');
        const o = this._obsFn(...args).pipe(
            takeUntil(this._cancel$),
            tap(res => {
                this._result = res;
                this.updateStatus('SUCCESS');
                if (this._showSuccessToast) ToastService.success(this._successMessage || `${capitalize(this._description)} was successful`);
            }),
            catchError(err => {
                this._error = err;
                this._errorMessage = extractErrorMessage(err, this._description, this._defaultErrorMessage, true, this._errorLookUpKeys, true);
                this.updateStatus('ERROR');
                if (this._showErrorToast) {
                    ToastService.error(this._errorMessage);
                }
                return throwError(err);
            }),
            finalize(() => {
                if (this.status === 'PENDING') this.updateStatus(null);
            }),
            delay(0),
            share(),
        );
        o.subscribe();
        return o;
    }

    cancel() {
        this._cancel$.next();
        this.updateStatus(null);
    }

    updateStatus(status: RequestStatus) {
        this._status = status;
        this._status$.next(status);
    }

    destroy() {
        this.cancel();
        this._cancel$.complete();
        this._destroy$.complete();
        this._status$.complete();
    }

    reset() {
        this.cancel();
        this._result = null;
        this.updateStatus(null);
    }
}
